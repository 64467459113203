import React from 'react'
import { WrapperContainer, WrapperSection } from '../../wrappers'
import { Grid } from '@material-ui/core'
import SideLine from '../../elements/SideLine/SideLine'
import { globals, s, colors } from '../../style'

const Stats = ({ data, theme }) => {
  const { slug_section, stats, title } = data
  return (
    <WrapperSection
      id={slug_section}
      extraCss={sSection}
      outside={<SideLine theme={theme}>{title}</SideLine>}>
      <WrapperContainer theme={theme}>
        <Grid container css={[sStats, { color: colors[theme].main }]}>
          {stats.map(
            ({ symbol, value, unit, title, description, image }, id) => (
              <Grid
                xs={12}
                sm={12}
                md={stats.length % 2 === 0 ? 6 : 4}
                lg={4}
                item
                css={[sStat]}
                key={id}>
                <div css={sStat.inner}>
                  <img
                    className="stat-icon"
                    src={image.publicURL}
                    alt={image.name}
                  />
                  <div className="stat-symbol">{symbol}</div>
                  <div className="stat-value">{value}</div>
                  <div className="stat-unit">{unit}</div>
                </div>
                <div className="stat-title">{title}</div>
                <div className="stat-description">{description}</div>
              </Grid>
            )
          )}
        </Grid>
      </WrapperContainer>
    </WrapperSection>
  )
}

const sSection = {
  contentVisibility: 'auto',
  [s.md]: {
    paddingTop: 0,
  },
  [s.lg]: {
    marginTop: globals.spacing.between.desktop,
    paddingTop: 0,
  },
}

const sStats = {
  padding: `${globals.spacing.inside.mobile * 2}px 0`,
}

const sStat = {
  display: 'flex',
  flexDirection: 'column',
  // position: 'relative',
  [s.sm_down]: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingBottom: globals.spacing.inside.mobile * 3,
    '&:last-of-type': { paddingBottom: 0 },
  },

  [s.md]: {
    padding: '0 1rem',
  },

  inner: {
    display: 'flex',
    position: 'relative',
  },

  '.stat-symbol, .stat-value, .stat-unit': {
    fontSize: '3rem',
    fontWeight: 700,
  },
  '.stat-title': {
    fontSize: '1rem',
    fontWeight: 700,
    opacity: 0.6,
    marginBottom: '1rem',
    [s.sm_down]: {
      maxWidth: '75%',
      fontSize: '1rem',
    },
  },
  '.stat-description': {
    fontSize: '0.875rem',
    fontWeight: 400,
    opacity: 0.8,
    [s.sm_down]: {
      maxWidth: '75%',
      fontSize: '0.75rem',
    },
  },
  '.stat-icon': {
    width: 64,
    height: 'auto',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translate(-100%, -50%)',
    opacity: 0.3,
    [s.xs]: { transform: 'translate(-50%, -50%) scale(2.5)', opacity: 0.08 },
    [s.sm]: { transform: 'translate(calc(100% + 32px), -50%)' },
    [s.md]: { transform: 'translate(-25%, -50%)' },
    [s.lg]: { transform: 'translate(-100%, -50%)' },
  },
}

export default Stats
